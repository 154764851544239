$toolbar-height: 72px;
$sidebar-width: 100px;
$block-height: calc((100vh - #{$toolbar-height}) / 3);
//$block-height: calc((100% - #{$toolbar-height}) / 3);

$div: hsla(0, 0%, 79%, .51);

$primary-color: #0095da;
$secondary-color: #58595b;
$gray-background: #9b9b9b;
$dark-gray-background: #050505;

$primary-color-background: rgba(0, 149, 218, 0.8);
$secondary-color-background: rgba(88, 89, 91, 0.8);

$text-color-on-light-background: rgba(0, 0, 0, .72);
$text-hover-color-on-light-background: rgba(0, 0, 0, .40);
$text-color-on-primary: #fff;
$text-color-on-secondary: #fff;

$icon-on-light-background: rgba(0, 0, 0, .54);

$min-desktop-width: 1200px;
$max-tablet-width: 768px;
$max-mobile-width: 600px;

//$tablet-version-width: 1200px;
$tablet-version-height: 899px;

@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}

@mixin placeholder() {
  position: relative;

  &:after {
    content: "";
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 20%, #dddddd 50%, #eeeeee 80%);
    position: absolute;
    -webkit-backface-visibility: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@mixin transition($what: all, $time: .25s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}
@mixin hover($color:$secondary-color,$hover:.06, $active:.1) {
  position: relative;

  &:before, &:after {
    @include transition(opacity);
    position: absolute;
    content: "";
    display: block;
    box-sizing: content-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //height: 100%;
    background-color: $color;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
  }

  &:hover:before {
    opacity: $hover;
  }

  &:active:after {
    opacity: $active;
  }
}

