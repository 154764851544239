@import "modern-normalize";
@import "vars";

page-home, page-contact, page-our-customers,
page-quarries-bela,
page-quarries-poljcane,
page-quarries-zagaj,
page-quarries-zamerk,
page-quarries-zusem,
page-production-poljcane,
page-production-zusem,
page-recycling {
  display: block;
  width: 100%;
  //overflow: auto;
}

html, body {
  //overflow: hidden;
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  font-size: 13px;
  line-height: 22px;
  color: $text-color-on-light-background;
}

b {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: $primary-color;
  font-weight: 600;
  margin-top: 32px;
  line-height: 1;
  margin-bottom: 24px;
  font-family: 'Montserrat', sans-serif;

  b {
    font-weight: 700;
  }
}

h2, h3, .h2, .h3 {
  font-size: 13px;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 15px;
}

h1, .h1 {
  font-size: 17px;
}

p {
  margin: 16px 0;
  font-size: .75rem;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
}

/* Handle */
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7;
}

.social-icon {
  display: inline-block;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;

  &--fb {
    background-image: url('../images/icons/fb-border.png');

    /*&:hover {
      background-image: url('../images/icons/fb-border-o.png');
    }*/
  }

  &--li {
    background-image: url('../images/icons/li-border.png');

    /*&:hover {
      background-image: url('../images/icons/li-border-o.png');
    }*/
  }

  &--yt {
    background-image: url('../images/icons/yt-border.png');

    /*&:hover {
      background-image: url('../images/icons/yt-border-o.png');
    }*/
  }
}

*[hidden] {
  display: none !important;
}

@media only screen and (min-width: #{$max-mobile-width}) {
  .hide-on-desktop {
    display: none;
  }
}


@import "button";
@import "main-footer";
@import "page-our-customers";

.container {
  margin: 0 auto;
  width: $min-desktop-width;
  max-width: 100%;
  padding: 0 24px;

  @media only screen and (max-width: #{$min-desktop-width}) {
    padding: 0;
  }
}

.featured-slider {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &-wrapper {
    display: block;
    width: 100%;
    min-height: 400px;
    padding-bottom: 40%;
    position: relative;
    overflow: hidden;
  }

  /*&-track {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
  }*/

  &__item {
    /*display: block;
    min-width: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;*/

    &-image {
      height: 100%;
      width: 100%;
      filter: grayscale(90%);
      object-fit: cover;
      object-position: center center;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: grid;
      grid-template-rows: 2fr auto 1fr;
      grid-template-columns: 1fr 240px 960px 1fr;
      grid-template-areas: "blackness blackness . ." "blackness blackness banner blue" "blackness blackness . .";
      color: $text-color-on-primary;

      @media only screen and (max-width: #{$min-desktop-width}) {
        grid-template-rows: 2fr auto 1fr;
        grid-template-columns: 20% 80%;
        grid-template-areas: "blackness ." "blackness banner" "blackness .";
      }

      @media only screen and (max-width: #{$max-mobile-width}) {
        grid-template-rows: 2fr auto 1fr;
        grid-template-columns: 100%;
        grid-template-areas: "." "banner" ".";
      }

      &-black {
        grid-area: blackness;
        height: 100%;
        width: 100%;
        display: block;
        background-color: rgba(0, 0, 0, .4);

        @media only screen and (max-width: #{$max-mobile-width}) {
          display: none;
        }
      }

      &-banner {
        grid-area: banner;
        background-color: $primary-color-background;
        text-align: right;
        font-size: .75rem;
        padding: 0 32px 24px;
        display: flex;
        justify-content: flex-end;
        position: relative;

        h1 {
          font-size: 2rem;
          line-height: 2rem;
          margin-top: -1.1rem;
          font-style: italic;
          color: currentColor;
        }

        p {
          font-weight: 600;
        }

        b {
          font-weight: 800;
        }

        ul {
          padding-inline-start: 16px;
        }

        &-action {
          height: 100%;
          display: flex;
          align-items: center;
          position: absolute;
          left: -32px;
        }

        &:after {
          position: absolute;
          content: "";
          height: 100%;
          left: 100%;
          width: 50vw;
          background-color: $primary-color-background;
        }

        @media only screen and (max-width: #{$max-mobile-width}) {
          flex-direction: column-reverse;
          align-items: flex-end;

          h1 {
            font-size: 1.5rem;
            margin-top: 20px;
          }

          &-action {
            left: 0;
            position: static;
          }
        }
      }
    }
  }

  &-nav {
    display: flex;
    flex-wrap: nowrap;
    color: $text-color-on-secondary;
    position: relative;
    padding: 0;

    &__item {
      @include hover(#fff);
      padding: 0 24px;
      height: 40px;
      line-height: 40px;
      flex-basis: 100%;
      background-color: $secondary-color;
      cursor: pointer;
      text-align: center;
      user-select: none;

      &:hover, &--active {
        background-color: $primary-color;
      }

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      @media only screen and (max-width: #{$max-mobile-width}) {
        background-color: $secondary-color-background;
        height: 8px;

        &:hover, &--active {
          background-color: $primary-color-background;
        }

        span {
          display: none;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      height: 100%;
      width: 50vw;
      //background-color: $primary-color;
      background-color: $secondary-color;
    }

    &:after {
      content: "";
      position: absolute;
      left: 100%;
      height: 100%;
      width: 50vw;
      background-color: $secondary-color;
    }
  }
}

.featured-container {
  padding-top: 0 !important;
  width: 100%;
  overflow: hidden;
  min-height: 100vh; //calc(100vh - #{$toolbar-height});
  display: flex;
  flex-direction: column;

  &-about {
    flex-grow: 1;
    display: flex;
    padding: 24px 0 32px 0;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    color: $secondary-color-background;
    opacity: .7;
    font-weight: 600;

    i.material-icons {
      opacity: .7;
      margin-top: 8px;
    }

    &__item {
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: default;
    }
  }
}

.main-header {
  display: block;
  width: 100%;
  color: $text-color-on-light-background;
  background-color: #fff;
  border: 1px solid transparent;
  box-sizing: border-box;
  height: $toolbar-height;
  z-index: 3;

  &.fixed {
    position: fixed;
    top: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &--primary {
    background-color: $primary-color;
    color: $text-color-on-primary;
  }

  &--sticky {
    border: 1px solid rgba(0, 0, 0, .1);
    position: sticky;
    top: -1px;
    transition: box-shadow 100ms linear;
  }

  &--stuck {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &__hamburger {
    display: none;
    margin: 0 8px;
    position: absolute;
    padding: 0;
    width: 56px;
    height: 56px;
    //top: 8px;
    //right: 8px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: $text-color-on-light-background;

    > i.material-icons {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  &--primary &__hamburger {
    color: $text-color-on-primary
  }

  &__content {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    height: 40px;
    width: 111px;
    margin: 0;

    @media only screen and (max-width: #{$min-desktop-width}) {
      margin: 0 24px;
    }
  }

  &-menu {
    display: block;
    position: relative;

    &__list {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
      margin: 0;
      list-style: none;

      &-item {
        list-style: none;
        //display: block;

        > a {
          text-transform: uppercase;
          padding: 0 16px;
          text-decoration: none;
          letter-spacing: 4px;
          font-weight: 600;
          font-size: 12px;
          height: $toolbar-height;
          line-height: $toolbar-height;
          color: currentColor;
          display: inline-flex;
          align-items: center;
          transition: color 200ms ease;


          &:hover {
            color: $secondary-color;
          }
        }

        /*&:last-child {
          margin-right: 48px;
          position: absolute;
          right: 0;
        }*/

        &--active {
          > a {
            font-weight: 700;
          }
        }
      }
    }
  }

  @media only screen and (max-width: #{$max-tablet-width}) {
    &__hamburger {
      display: flex;
    }
    &-menu__list {
      display: none;
    }

    &-menu {
      position: static;
    }
  }

  &-sub {
    position: absolute;
    top: $toolbar-height;
    background-color: $secondary-color;
    left: 0;
    right: 0;
    display: flex;
    z-index: 1;

    &:after {
      display: block;
      content: "";
      position: absolute;
      height: 32px;
      left: 100%;
      background-color: $secondary-color;
      width: calc(50vw - 580px);
    }

    &__item {
      display: block;
      @include hover(#fff);
      padding: 0 16px;
      height: 32px;
      line-height: 32px;
      text-decoration: none;
      color: $text-color-on-secondary
    }

    @media only screen and (max-width: #{$max-tablet-width}) {
      left: 0;
      right: 0;
    }
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  width: 300px;
  transition: all 200ms linear;
  z-index: 10;
  background-color: $primary-color;
  display: flex;
  overflow: hidden;

  &--visible {
    transform: translateX(300px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &--visible + &-scrim {
    display: block;
  }

  &__close {
    position: absolute !important;
    padding: 0;
    width: 56px;
    height: 56px;
    top: 8px;
    right: 8px;
    background-color: transparent;
    color: $text-color-on-primary;
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    margin: 64px 0 0 0;
    overflow-y: auto;
    display: flex;
  }

  &__nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &-item {
      @include hover;
      display: flex;
      align-items: center;
      padding: 8px 24px 8px 48px;
      min-height: 32px;
      line-height: 32px;
      text-decoration: none;
      color: #fff;
      font-weight: 700;
      letter-spacing: 3px;
      font-size: .75rem;

      /*&:hover {
        color: $secondary-color
      }*/
    }
  }

  &__footer {
    flex-grow: 1;
    min-height: 92px;
    padding: 56px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
  }

  &-scrim {
    z-index: 9;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
  }
}

.buttons {
  display: flex;

  > * {
    margin-right: 8px;
  }
}

.screen-section {
  width: 100%;
  overflow: hidden;
  min-height: calc(100vh - #{$toolbar-height});
}

.logo-text {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, .4);
  font-style: italic;

  b {
    text-transform: uppercase;
    color: $primary-color
  }

  &--dark {
    color: rgba(255, 255, 255, .4);

    b {
      color: $text-color-on-primary
    }
  }

  @media only screen and (max-width: #{$max-tablet-width}) {
    margin-top: 48px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  color: $secondary-color;

  &--secondary {
    background-color: $secondary-color;
    color: $text-color-on-secondary;
    //font-size: .75rem;
  }

  &--secondary &-item--active &-item__text {
    border-bottom-color: transparent;
  }

  &--secondary &-item--active {
    background-color: $primary-color;
  }

  &--secondary &-item {
    padding: 0 16px 0 24px;
    height: 40px;
    line-height: 40px;
  }

  &-item {
    color: currentColor;
    cursor: pointer;
    user-select: none;
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
    @include hover(#fff);

    &__text {
      display: inline-block;
      border-bottom: 2px solid transparent;

      &--placeholder {
        display: inline-block;
        height: 20px;
        width: 200px;
        max-width: 100%;
        background-color: rgba(0, 0, 0, .1);
        border-bottom: 0 solid transparent !important;
        @include placeholder;
      }
    }
  }

  &--border-animation {
    overflow: hidden;
    padding-bottom: 2px;
  }

  &--border-animation &-item {
    margin-top: 2px;

    &__text {
      border-bottom: 2px solid transparent;
    }

    &__underline {
      display: block;
      position: absolute;
      width: 100%;
      bottom: -2px;
      background-color: rgba(0, 0, 0, .2);
      z-index: -1;
      height: 2px;
      @include transition;
    }

    &--active .list-item__text, &:hover .list-item__text {
      border-bottom-color: $primary-color;
    }

    &--active .list-item__text {
      font-weight: 600;
    }

    &--active .list-item__underline, &:hover .list-item__underline {
      transform: translateX(-100%);
    }
  }
}

.text {
  &-subtitle {
    color: $primary-color;
    text-transform: uppercase;
    font-size: .9rem;

    &--dark {
      color: $text-color-on-primary
    }
  }

  &-subtitle-2 {
    color: $primary-color;
    font-size: .9rem;
    text-transform: none;

    &--dark {
      color: $text-color-on-primary
    }
  }
}

.photoswipe-video {

  &-view {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .6);
    }

    &__play-button.material-icons {
      @include hover(#fff);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      color: $text-color-on-primary;
      background-color: $primary-color;
      border-radius: 50%;
      line-height: 56px;
      font-size: 2.5rem;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

      }
    }
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    > video {
      max-width: 100vw;
    }

    > iframe {
      width: 100%;
      max-width: 100vw;
    }

    &__iframe-wrapper {
      width: calc(100% - 64px);
      padding-bottom: 56.25%;
      max-height: calc(100vh - 128px);
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      > iframe {
        margin: 64px 0 0 0;
        max-height: calc(100vh - 128px);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      @media (orientation: landscape) {
        width: calc(100% - 64px);
        height: calc(100% - 64px);
        padding-bottom: 0;
      }
    }
  }
}

.section {
  &--full-page {
    min-height: 100vh;

    > .fp-tableCell {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }

    &.section-events, &.section-events > .fp-tableCell {
      height: 100vh;

      .main-header {
        display: block;
      }
    }

    &.section-about {
      height: calc(100vh - #{$toolbar-height}) !important
    }

    &.section-about > .fp-tableCell {
      min-height: calc(100vh - #{$toolbar-height}) !important;
      height: calc(100vh - #{$toolbar-height}) !important;
      //display: grid !important;
    }
  }

  &-events, &-events > .fp-tableCell {
    padding-top: 0 !important;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 720px 480px 1fr;
    grid-template-rows: 72px calc(100vh - 72px);
    grid-template-areas: "header header header header" ". left right .";
    position: relative;

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 60% 40%;
      grid-template-areas: "header header" "left right";
    }

    @media only screen and (max-width: #{$max-mobile-width}) {
      grid-template-columns: 100%;
      grid-template-rows: auto 200px;
      grid-template-areas: "left" "right";
    }

    .main-header {
      grid-area: header;
      display: none;
      box-shadow: none !important;
    }

    &__left, &__right {
      flex-basis: 50%;
    }

    &__left {
      grid-area: left;
      display: flex;
      flex-direction: column;
      padding: 24px 64px 48px 24px;

      &-spacer {
        flex-grow: 1;
      }

      &-footer {
        padding: 16px 0;
        display: flex;
        justify-content: flex-end;
      }

      &-background {
        position: absolute;
        left: -5%;
        bottom: -5%;
        background-image: url("../images/bg.png");
        width: 40vw;
        height: 40vw;
        max-height: 60vh;
        background-size: cover;
        background-repeat: no-repeat;
        filter: invert(5%);
        z-index: -1;
      }
    }

    &-gallery {
      max-height: calc(100vh - #{$toolbar-height});
      grid-area: right;
    }

    &__title {
      margin: 32px 0 0 0;
      text-transform: none;

      &--placeholder {
        height: 20px;
        width: 200px;
        max-width: 100%;
        background-color: rgba(0, 0, 0, .1);
        border-bottom: 0 solid transparent !important;
        @include placeholder;
      }
    }

    &__body-placeholder {
      height: 200px;
      max-height: 100%;
      width: 400px;
      max-width: 100%;
      background-color: rgba(0, 0, 0, .1);
      border-bottom: 0 solid transparent !important;
      @include placeholder;
    }

    p {
      text-align: justify;
    }

    &__content {

      &-item {
        display: none;

        &--visible {
          display: block;
        }
      }
    }
  }

  &-events {
    min-height: calc(100vh - 72px);
    grid-template-rows: calc(100vh - 72px);
    grid-template-areas: ". left right .";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 60% 40%;
      grid-template-areas: "left right";
    }

    @media only screen and (max-width: #{$max-mobile-width}) {
      grid-template-columns: 100%;
      grid-template-rows: auto 200px;
      grid-template-areas: "left" "right";
    }
  }

  &-about {
    min-height: calc(100vh - #{$toolbar-height} + 1px);
  }

  &-about, &-about > .fp-tableCell {
    display: grid;
    max-width: 100%;
    overflow: hidden;
    grid-template-columns: 1fr 720px 480px 1fr;
    grid-template-rows: 200px auto #{$footer-height};
    grid-template-areas: "blue about slider slider" "blue about video video" "footer footer footer footer";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 60% 40%;
      grid-template-rows: 200px auto auto;
      grid-template-areas: "about slider" "about video" "footer footer";

      &-blue {
        display: none;
      }
    }

    @media only screen and (max-width: #{$max-mobile-width}) {
      margin-top: 72px;
      grid-template-columns: 100%;
      grid-template-rows: auto 200px 200px auto;
      grid-template-areas: "about" "slider" "video" "footer";

      &-blue {
        display: none;
      }
    }

    .main-footer {
      grid-area: footer;
    }

    &-blue {
      grid-area: blue;
      background-color: $primary-color;
    }

    &-about {
      grid-area: about;
      background-color: $primary-color;
      color: $text-color-on-primary;
      padding: 24px 64px 0 24px;
      box-sizing: border-box;
    }

    &-slider {
      grid-area: slider;
    }

    &-video {
      grid-area: video;
      background-image: url(../images/video.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
    }

    &-contacts {
      color: $text-color-on-primary;
      //padding: 48px 0;
      font-size: .7rem;

      &__item {
        //margin-right: 84px;
        display: flex;

        > * {
          display: block;
          min-width: 140px;
        }

        i {
          color: $primary-color
        }

        @media only screen and (max-width: #{$max-mobile-width}) {
          flex-direction: column;
        }
      }

      &__divider {
        padding-top: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid rgba(255, 255, 255, .9);
      }
    }
  }

  &-quarries {
    min-height: calc(100vh - #{$toolbar-height} - 200px);
    display: grid;
    padding-left: 24px;
    grid-template-columns: 1fr 720px 480px 1fr;
    grid-template-areas: ". left right right";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 60% 40%;
      //grid-template-rows: 200px auto;
      grid-template-areas: "left right";
    }

    @media only screen and (max-width: #{$max-tablet-width}) {
      grid-template-columns: 100%;
      grid-template-rows: 200px auto;
      grid-template-areas: "right" "left";
      padding-left: 0;

      .section-quarries__left {
        padding-left: 24px;
        padding-right: 24px;
      }

      .section-quarries__left-side .list {
        margin-left: 0;
      }
    }

    &__left {
      grid-area: left;
      padding-top: 48px;
      padding-right: 64px;

      @media only screen and (max-width: #{$max-mobile-width}) {
        padding-top: 0;
        padding-right: 0;
      }

      &-side-map {
        display: flex;
        margin-bottom: 32px;

        @media only screen and (max-width: #{$max-mobile-width}) {
          flex-direction: column;
        }
      }

      &-side {
        flex-basis: 35%;
        padding-right: 32px;

        .list {
          margin-left: -24px;
        }

        @media only screen and (max-width: #{$max-mobile-width}) {
          padding-right: 0;
        }
      }

      &-map {
        flex-basis: 65%;

        &-coordinates {
          display: flex;
          justify-content: flex-end;
        }

        &-element {
          width: 100%;
          height: 140px;
          margin-bottom: 24px;
        }

        &-contact {
          display: flex;
          font-size: .7rem;

          > * {
            flex-basis: 50%;
          }
        }

        &-div {
          padding-top: 12px;
          border-bottom: 1px solid rgba(0, 0, 0, .4);
          margin-bottom: 12px;
        }
      }
    }

    &__right {
      grid-area: right;
    }

    &__gallery {
      height: 250px;
    }
  }

  &-production {
    min-height: calc(100vh - #{$toolbar-height});
    display: grid;
    grid-template-columns: 1fr 600px 600px 1fr;
    grid-template-rows: auto 200px;
    grid-template-areas: "video video content ." "video video gallery gallery";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 50% 50%;
      //grid-template-rows: 200px auto;
      grid-template-areas: "video content" "video gallery";
    }

    @media only screen and (max-width: #{$max-tablet-width}) {
      grid-template-columns: 100%;
      grid-template-rows: 200px auto 200px;
      grid-template-areas: "video" "content" "gallery";
      padding-top: 31px;

      .section-production-contacts {
        display: none;
      }
      .section-production-content {
        padding-left: 24px;
        padding-top: 0;
      }
    }

    &-video {
      grid-area: video;
      position: relative;
    }

    &-contacts {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $text-color-on-primary;
      padding: 48px 0;
      font-size: .7rem;

      &__item {
        margin-right: 84px;

        > span {
          display: inline-block;
          min-width: 130px;
        }
      }

      &__divider {
        padding-top: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, .9);
      }
    }

    &-content {
      padding-top: 31px;
      grid-area: content;
      padding-left: 48px;
      padding-bottom: 32px;
      padding-right: 24px;
    }

    &-gallery {
      grid-area: gallery;
    }
  }

  &-recycling {
    min-height: calc(100vh - #{$toolbar-height});
    display: grid;
    grid-template-columns: 1fr 600px 600px 1fr;
    grid-template-rows: auto 200px;
    grid-template-areas: "video video content ." "video video gallery gallery";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 50% 50%;
      //grid-template-rows: 200px auto;
      grid-template-areas: "video content" "video gallery";
    }

    @media only screen and (max-width: #{$max-tablet-width}) {
      grid-template-columns: 100%;
      grid-template-rows: 200px auto 200px;
      grid-template-areas: "video" "content" "gallery";

      .section-production-contacts {
        display: none;
      }
      .section-production-content {
        padding-left: 24px;
      }
    }

    &-video {
      grid-area: video;
      position: relative;
      background-position: 70% center;
    }

    &-contacts {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $text-color-on-primary;
      padding: 48px 0;
      font-size: .7rem;

      &__item {
        margin-right: 84px;

        > span {
          display: inline-block;
          min-width: 130px;
        }
      }

      &__divider {
        padding-top: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, .9);
      }

      @media only screen and (max-width: #{$max-mobile-width}) {
        display: none;
      }
    }

    &-content {
      grid-area: content;
      padding-left: 48px;
      padding-bottom: 32px;
      padding-right: 24px;
    }

    &-gallery {
      grid-area: gallery;
      overflow: hidden;

      &__track {
        display: flex;
        flex-wrap: nowrap;
      }

      &__item {
        height: 200px;
        object-fit: cover;
      }
    }
  }

  &-contact {
    min-height: calc(100vh - #{$toolbar-height} - #{$footer-height});
    display: grid;
    grid-template-columns: 1fr 200px 500px 500px 1fr;
    grid-template-areas: "map map people address .";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto 200px;
      grid-template-areas: "people address" "map map";

      .section-contact-people, .section-contact-address {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    @media only screen and (max-width: #{$max-tablet-width}) {
      grid-template-columns: 100%;
      grid-template-rows: 200px auto auto;
      grid-template-areas: "map" "people" "address";

      .section-contact-people, .section-contact-address {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    &-map {
      grid-area: map;
    }

    &-people {
      grid-area: people;
      padding: 0 32px 48px 64px;
    }

    &-address {
      grid-area: address;
      padding-left: 32px;
      padding-bottom: 48px;
      padding-right: 24px;
    }

    &-contacts {
      color: $text-color-on-light-background;
      //padding: 48px 0;
      font-size: .7rem;

      &__item {
        //margin-right: 84px;
        display: flex;

        > * {
          display: block;
          min-width: 140px;
        }

        i {
          color: $primary-color
        }
      }

      &__divider {
        padding-top: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, .9);
      }
    }

    &-form {
      display: grid;
      grid-template-columns: 1fr 600px 300px 300px 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: ". textarea col1 col2 ." ". button . . .";
      background-color: $primary-color;
      padding: 48px 24px;
      color: $text-color-on-primary;
      position: relative;

      @media only screen and (max-width: #{$min-desktop-width}) {
        grid-template-columns: 50% 25% 25%;
        grid-template-areas: "textarea col1 col2" "button . .";
      }

      @media only screen and (max-width: #{$max-tablet-width}) {
        grid-template-columns: 50% 50%;
        grid-template-rows: 200px auto auto;
        grid-template-areas:
                "textarea textarea"
                "col1 col2"
                "button button";

        .section-contact-form__textarea {
          margin: 0 0 8px 0;
        }

        .section-contact-form__col {
          margin: 0;
        }

        .section-contact-form__col-1 {
          margin-right: 4px;
        }
        .section-contact-form__col-2 {
          margin-left: 4px;
        }

        .section-contact-form__button {
          position: static;
          margin: 16px 0 0 0;
          bottom: 0
        }
      }

      &__textarea {
        grid-area: textarea;
        min-height: 56px;
        margin: 0 8px 0 0;
      }

      &__col {
        display: flex;
        flex-direction: column;
        margin: 0 8px;

        &-1 {
          grid-area: col1;
        }

        &-2 {
          grid-area: col2;
        }

        div {
          display: flex;
          flex-wrap: nowrap;

          > input:first-child {
            flex-basis: 35%;
            border-right-width: 1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-color: rgba(255, 255, 255, .1) !important;
          }

          > input:last-child {
            flex-basis: 65%;
            border-left-width: 1px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-color: rgba(255, 255, 255, .1) !important;
          }
        }
      }

      input {
        min-width: 45px;

      }

      &__textarea, input {
        @include transition;
        font-family: inherit;
        background-color: rgba(255, 255, 255, .1);
        border: 2px solid rgba(255, 255, 255, .3);
        border-radius: 3px;
        outline: none;
        padding: 8px 16px;
        margin-bottom: 8px;
        min-height: 40px;
        font-size: .75rem;
        line-height: 1rem;
        color: currentColor;

        &::placeholder {
          color: currentColor;
          opacity: 1
        }

        &:hover, &:focus {
          //background-color: rgba(255, 255, 255, .2);
          border: 2px solid rgba(255, 255, 255, .5);
        }

        &:focus {
          //background-color: rgba(255, 255, 255, .2);
          border: 2px solid rgba(255, 255, 255, .8);
        }
      }

      &__button {
        grid-area: button;
        position: absolute;
        margin: 0 24px;
        bottom: -68px
      }

    }
  }

  &-thank-you {
    min-height: calc(100vh - #{$toolbar-height} - #{$footer-height});
    display: grid;
    grid-template-columns: 1fr 1200px 1fr;
    grid-template-areas: ". content .";

    @media only screen and (max-width: #{$min-desktop-width}) {
      grid-template-columns: 100%;
      grid-template-areas: "content";
    }

    &__content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.swiper-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.swiper-slide > img {
  min-width: 100%;
  object-fit: cover;
  height: 100%;
}
