@import "vars";

page-our-customers {
  display: block;
}

.customers-list {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - #{$toolbar-height} - #{$block-height});
  min-height: calc((var(--vh, 1vh) * 100) - #{$toolbar-height} - #{$block-height});
  transition: all 500ms ease;
  width: calc(100% + 1px);
  padding: 0;

  &-wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__item {
    $item: &;
    min-height: 250px;
    transition: all 500ms ease;
    min-width: 200px;
    //max-width: 600px;
    width: 20%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 20%;
    max-width: 20%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;

    .button {
      position: absolute;
      left: -16px;
      bottom: 24px
    }

    &--add {
      //max-width: 0;
      //animation: flexGrow 500ms ease forwards;
    }

    &--remove {
      //animation: flexShrink 500ms ease forwards;
    }

    /*&--odd {
      flex-direction: row-reverse;

      .button {
        left: auto;
        right: -16px;
      }
    }*/

    /*&:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n-2) {
      flex-direction: row-reverse;

      .button {
        left: auto;
        right: -16px;
      }
    }*/

    &-background {
      display: block;
      /*width: 100%;
      height: 100%;*/
      //max-width: 50%;
      /*max-height: 50%;*/
      object-fit: cover;
      flex-grow: 1;
      flex-shrink: 1;
      /*flex-basis: 50%;*/
      position: relative;

      > img {
        position: absolute;
        top: 8px;
        left: 8px;
        right: 8px;
        margin: 0 auto;
        bottom: 8px;
        /*width: 100%;
        height: 100%;*/
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &-description {
      display: block;
      padding: 16px 24px 24px 32px;
      //flex-grow: 1;
      flex-shrink: 1;
      /*flex-basis: 50%;*/
      position: relative;

      &__category {
        font-size: 9px;
        color: $secondary-color;
        font-weight: 600;
        text-transform: lowercase;
      }

      &__title {
        font-size: 13px;
        color: $primary-color;
        margin: 0 0 24px 0;
        font-weight: 400;

        b {
          font-weight: 800;
          text-transform: uppercase;
        }
      }

      &__text {
        display: block;
        margin: 0;
        color: $text-color-on-light-background;
        font-size: 11px;
        line-height: 18px;
      }
    }
  }

  @media only screen and (max-width: #{$max-mobile-width}) {
    /*flex-direction: column;
    flex-wrap: nowrap;*/

    &__item {
      max-width: 100%;

      /*&:nth-child(6n), &:nth-child(6n-1), &:nth-child(6n-2) {
        flex-direction: row;

        .button {
          left: -16px;
          right: unset;
        }
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;

        .button {
          left: auto;
          right: -16px;
        }
      }*/
    }
  }
}
