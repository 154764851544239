@import "vars";

$footer-height: 248px;

.main-footer {
  border-top: 4px solid $secondary-color;
  min-height: 248px;
  display: grid;
  max-width: 100%;
  overflow: hidden;
  grid-template-columns: 1fr ($min-desktop-width / 5) ($min-desktop-width / 5) ($min-desktop-width / 5) ($min-desktop-width / 5 * 2) 1fr;
  grid-template-rows: 1fr auto auto 1fr;
  grid-template-areas: ". . . . . ." ". a b c d ." ". copyright copyright copyright copyright ." ". . . . . .";
  background-color: rgba(0, 0, 0, .1);

  @media only screen and (max-width: #{$min-desktop-width}) {
    grid-template-columns: 0 20% 20% 20% 40% 0;
  }

  @media only screen and (max-width: #{$max-tablet-width}) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 64px auto auto auto auto 64px;
    grid-template-areas:
            ". ."
            "a d"
            "b d"
            "c d"
            ". copyright"
            ". .";

    &__column {
      padding: 0 !important;
      margin: 0 24px 32px 24px;
    }
  }

  @media only screen and (max-width: #{$max-mobile-width}) {
    grid-template-columns: 100%;
    grid-template-rows: 64px auto auto auto auto auto 64px;
    grid-template-areas:
            "."
            "a"
            "b"
            "c"
            "d"
            "copyright"
            ".";

    &__column {
      padding: 0 !important;
      margin: 0 24px 32px 24px;
    }
  }

  &__column {
    &-1 {
      grid-area: a;
      padding-left: 24px;
    }

    &-2 {
      grid-area: b;
    }

    &-3 {
      grid-area: c;
    }

    &-4 {
      grid-area: d;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
    }

    &-title {
      color: $primary-color;
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0 0 8px;
      letter-spacing: 2px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text-line {
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-size: .7rem;

    b {
      font-weight: 700;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: $secondary-color
      }
    }

    &--uppercase {
      text-transform: uppercase;
    }

    .colored {
      color: $secondary-color
    }
  }

  a.main-footer__text-line:hover {
    color: $secondary-color
  }

  &__copyright {
    grid-area: copyright;
    padding: 16px 24px 0;
  }

  &__copyright, &__addendum {
    text-align: right;
    color: $secondary-color;
    font-size: .7rem;

    a {
      text-decoration: none;
      color: #000
    }
  }

  .cert-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 70px;
    justify-content: flex-end;

    > * {
      max-width: 74px;
      margin-right: 24px;
    }

    .cert {
      display: inline-flex;

      &__9001 {
        background-image: url('../images/certificates/9001.png');
        background-size: contain;
        background-repeat: no-repeat;
        flex-grow: 1;
        filter: invert(100%);
      }

      &__14001 {
        background-image: url('../images/certificates/14001.png');
        background-size: contain;
        background-repeat: no-repeat;
        flex-grow: 1;
        filter: invert(100%);
      }

      &__18001 {
        background-image: url('../images/certificates/18001.png');
        background-size: contain;
        background-repeat: no-repeat;
        flex-grow: 1;
        margin-right: 0;
        filter: invert(100%);
      }
    }
  }

  .social {
    margin-top: 8px;
    line-height: 20px;
    display: flex;

    span {
      padding-left: 10px;
      text-transform: none;
      font-size: 11px;
      vertical-align: super;
    }

    &__icon {
      height: 20px;
      filter: invert(100%);
      margin-right: 8px;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-flex;
      width: 20px;

      &.fb {
        background-image: url('../images/icons/fb-border.png');
      }

      &.yt {
        background-image: url('../images/icons/yt-border.png');
      }

      &.li {
        background-image: url('../images/icons/li-border.png');
      }
    }

    &:hover {
      .social__icon {
        &.fb {
          background-image: url('../images/icons/fb-border-o.png');
        }

        &.yt {
          background-image: url('../images/icons/yt-border-o.png');
        }

        &.li {
          background-image: url('../images/icons/li-border-o.png');
        }
      }
    }

  }

}
