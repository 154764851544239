@import "vars";

.buttons {
  display: flex;
  flex-wrap: wrap;

  > .button {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.button {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  //background-color: $secondary-color;
  border: none;
  color: $text-color-on-light-background;
  font-size: .75rem;
  line-height: 20px;
  padding: 4px 16px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  flex-basis: auto;
  width: fit-content;
  transition: all 200ms ease;
  border-radius: 3px;

  &--placeholder {
    background-color: rgba(0, 0, 0, .1);
    @include placeholder;
  }

  &:not(&--placeholder) {
    @include hover;
  }

  &--skewed {
    transform: skew(-15deg);
  }

  &--small {
    font-size: 10px;
  }

  &--icon {
    padding-left: 0;

    &-end {
      padding-right: 0;
    }
  }

  &--unelevated {
    @include hover(#fff);
    background-color: $secondary-color;
    color: $text-color-on-secondary;
  }

  &--featured {
    @include hover(#fff);
    background-color: #000;
    border-radius: 0;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &--primary {
    background-color: $primary-color;
    color: $text-color-on-primary;
  }

  &--bordered {
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, .4);
    color: rgba(0, 0, 0, .4);
    //font-weight: 900;
    //letter-spacing: 2px;
    //text-transform: uppercase;
    //font-size: 10px;


    &:hover {
      color: $secondary-color;
      border: 2px solid $secondary-color;
    }
  }

  i.material-icons {
    line-height: 18px;
    font-size: 18px;
    margin: 0 8px;
  }

  * {
    margin-right: 8px;
  }

  *:last-child {
    margin-right: 0;
  }
}

.icon {
  min-width: 32px;
  min-height: 32px;
  display: block;

  &__position {
    height: 44px;
    bottom: 4px;
    width: 40px;
    position: relative;
  }

  &__orange {
    background-color: $secondary-color;
  }

  &--small {
    min-width: 20px;
    min-height: 20px;
    background-size: 10px !important;
  }

  &__chevron-right {
    background-image: url("../images/icons/chevron-right.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
  }

  &__chevron-left {
    background-image: url("../images/icons/chevron-right.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
    transform: rotateY(180deg);
  }

  &__chevron-up {
    background-image: url("../images/icons/chevron-right.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px;
    transform: rotate(-90deg);
  }

  &__dot {
    //background-image: url(../images/gallery-nav-green.png);
    background-image: url(../images/icons/360-degrees.png);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center center;
  }
}

.svg-icon {
  &__path {
    fill: $icon-on-light-background;
  }

  &--white &__path {
    fill: #fff;
  }

  &--hover-effect:hover &__path {
    fill: $secondary-color;
  }
}

.button .icon {
  margin: 0 8px;
}
